import React from 'react';
import {
  Layout,
  SectionHeroBanner,
  SectionAboutBusiness,
  SectionServices,
} from '@websites/components';
import { YellowBusinessContext } from '../contexts/YellowBusinessContext';

const IndexPage = () => {
  const { yellowBusiness, sections } = React.useContext(YellowBusinessContext);

  const hasTheme = !!yellowBusiness?.website?.theme;

  return (
    <>
      {hasTheme && (
        <Layout
          headerTransparent
          yellowBusiness={yellowBusiness}
          sections={sections}
        >
          <SectionHeroBanner
            yellowBusiness={yellowBusiness}
          />
          <SectionAboutBusiness
            section={sections.aboutBusinessSection}
            yellowBusiness={yellowBusiness}
          />
          <SectionServices
            section={sections.servicesSection}
            yellowBusiness={yellowBusiness}
          />
        </Layout>
      )}
    </>
  );
};

export default IndexPage;
